import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Menu, Row, Col } from "antd";

import { siderService } from "../../common/siderBase";

export default class Sider extends Component {

	render() {

		return (
			<React.Fragment>
				<div className="connect-sider">
					<Row className="nopadding">
						<Col flex={1}>
							<a href="https://www.facebook.com/ChothuexeRATXIN" target="_blank">
								<i className="fab fa-facebook-f"></i>
							</a>
						</Col>
						<Col flex={1}>
							<a href="tel:0899184307">
								<i className="fas fa-phone-alt"></i>
							</a>
						</Col>
					</Row>
				</div>
				<Menu
					className="sider-menu"
					defaultOpenKeys={["productCategory"]}
					mode="inline"
					inlineIndent={20}
					onSelect={() => siderService.visible()}
				>
					<Menu.Item key="000001">
						<Link to="/">
							<i className="icon fal fa-home"></i>
							<span>Trang chủ</span>
						</Link>
					</Menu.Item>
					<Menu.Item key="000003">
						<Link to="/chinh-sach">
							<i className="icon fal fa-question"></i>
							<span>Chính sách</span>
						</Link>
					</Menu.Item>
					<Menu.Item key="000002">
						<Link to="/lien-he">
							<i className="icon fal fa-envelope-open"></i>
							<span>Liên hệ</span>
						</Link>
					</Menu.Item>
					<Menu.Divider />
				</Menu>
			</React.Fragment>
		);
	}
}
