import React, { Component } from "react";

import { HomeContentService } from "../content/contentService";

export default class Policy extends Component {
  constructor(props) {
		super(props);

		this.state = {
			fullName: null,
			email: null,
			title: null,
			content: null,
		};
	}

  componentDidMount() {
		HomeContentService.getList({ idList: [20005], hasDescription: true }).then((res) => {
			if (!res.success) return;

			this.setState({
				policy: res.itemList[0],
			});
		});
	}

  render() {
    return(
      <section className="contact-page">
        <div className="container">
          <div className="content">
							<div className="title-home">Chính sách</div>
							<div className="description-home">
              {this.state.policy && 
                (<div dangerouslySetInnerHTML={{ __html: this.state.policy.description }}></div>)}
							</div>
						</div>
        </div>
      </section>
    );
  }
}