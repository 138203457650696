import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Affix, Button } from "antd";
import Media from "react-media";

import * as Const from "../../common/const";
import SiderBase, { siderService } from "../../common/siderBase";
import Sider from "./sider";
import { CurrentUserService } from "../user/currentUserService";
import { LoginService } from "../user/userService";
import { LogActionService } from "../logAction/logActionService";

import MenuMain from "./menuMain";
import MenuTop from "./menuTop";

export default class Header extends Component {
	constructor(props) {
		super(props);

		this.state = {
			userCurrent: CurrentUserService.getUser(),
			mainbarAffix: "",
		};
	}

	componentDidMount() {
		this.userCurrentSubscription = CurrentUserService.getObservable().subscribe((user) => {
			this.setState({ userCurrent: user });
		});
	}

	componentWillUnmount() {
		this.userCurrentSubscription.unsubscribe();
	}

	mainbarAffixChange = (affixed) => this.setState({ mainbarAffix: affixed ? "affix" : "un-affix" });

	onLogout = () => {
		LoginService.logoutSubmit();
		window.location = "/";
	};

	onLogAction = async (action) => {
		const res = await LogActionService.submit({
			action: action,
		})
	}

	render() {
		const { mainbarAffix } = this.state;

		return (
			<header className="header">
				<Media query={Const.media.largeQuery} render={() =>
					(
						<div className="topbar">
							<div className="container">
								<div className="left">
									<div className="header-contact">
										<a href="tel:0899184307" onClick={() => this.onLogAction("call header")}><i className="fal fa-phone-alt" /><span>0899.184.307</span></a>
										<a href="mailto:chothuexeratxin@gmail.com"><i className="fal fa-envelope" /><span>chothuexeratxin@gmail.com</span></a>
									</div>
								</div>
								<div className="right">
									<MenuTop />
								</div>
							</div>
						</div>
					)}
				/>
				<Affix onChange={this.mainbarAffixChange} offsetTop={-70} className="ant-affix-wrapper">
					<div className={"mainbar " + mainbarAffix}>
						<div className="container">
							<div className="left">
								<Media
									query={Const.media.smallQuery}
									render={() => (
										<a className="sider-button" onClick={() => { siderService.visible();}}>
											<i className="fal fa-bars"></i>
										</a>
									)}
								/>
							</div>
							<div className="left">
								<Link to="/" className="site-logo"> <img src="/image/logo.png" alt=""/> </Link>
							</div>
							<div className="center">
								<a href="tel:0899184307" onClick={() => this.onLogAction("call header mobile")} className="button"><Button className="ant-btn-primary"><i className="fas fa-phone-alt"></i> Bấm gọi</Button>  </a>
							</div>
							<div className="right">
								<Media query={Const.media.largeQuery} render={() => <MenuMain />} />
							</div>
						</div>
					</div>
				</Affix>
				<SiderBase colapse>
					<Sider />
				</SiderBase>
			</header>
		);
	}
}
