import React, { Component } from "react";
import { Row, Col, Input, Button, Modal, Select, Pagination, Dropdown, Menu } from "antd";

import { Popup, PopMessage } from "../../common/component";
import { Format } from "../../common/format";

import { FeedbackManageService, FeedbackPostService } from "./feedbackService";
import FeedbackPost from "./feedbackPost";


export default class FeedbackManage extends Component {
	state = {
		isItemModel: false,
		itemId: null,
      itemList: [],
      pageIndex: 1,
		pageSize: 20,
		totalPages: 0,
		totalItems: 0,
      orderBy: "latest",
	};

	componentDidMount() {
		this.getList();
	}

	getList = () => {
		FeedbackManageService.getList({
         orderBy: this.state.orderBy,
			pageIndex: this.state.pageIndex,
      }).then((res) => {
			if (!res.success) return;

			this.setState({
				itemList: res.itemList,
				pageIndex: res.pageIndex,
				totalPages: res.totalPages,
				totalItems: res.totalItems,
			});
		});
	};

	onAddItem = () => {
		this.setState({
			itemId: null,
			isItemModel: true,
		});
	};

	onEditItem = (item) => {
		this.setState({
			itemId: item.id,
			isItemModel: true,
		});
	};

	onItemCancel = () => {
		this.setState({
			isItemModel: false,
		});
	};

	onItemOk = () => {
		this.getList();

		this.setState({
			isItemModel: false,
		});
	};

	render() {
		return (
			<React.Fragment>
            <h1>Quản lý feedback</h1>
				<Row align="middle" justify="end">
					<Col flex="auto">{this.state.totalItems} Kết quả</Col>
					<Col>
						<Button type="primary" onClick={this.onAddItem}>
							<i className="fa fa-plus" />
							<span>Thêm</span>
						</Button>
					</Col>
					<Col>
						<Select
							placeholder="Sắp xếp"
							style={{ width: "140px" }}
							value={this.state.orderBy}
							onChange={(value) => this.setState({ orderBy: value }, () => this.getList())}
						>
							<Select.Option value="latest">Mới nhất</Select.Option>
							<Select.Option value="oldest">Cũ nhất</Select.Option>
						</Select>
					</Col>
					<Col style={{ marginLeft: "auto" }}>
						<Pagination
							current={this.state.pageIndex}
							total={this.state.totalItems}
							pageSize={this.state.pageSize}
							showSizeChanger={false}
							onChange={(value) => this.setState({ pageIndex: value }, () => this.getList())}
						/>
					</Col>
				</Row>
				<div className="box">
					<div className="table has-left-col has-menu">
						<ol>
							<li>#</li>
							<li>Hình ảnh</li>
							<li>Tên</li>
							<li>Nội dung</li>
							<li>Ngày đăng</li>
							<li></li>
						</ol>
						{this.state.itemList.map((item) => (
							<ul key={item.id}>
								<li className="id-col collapsing">{item.id}</li>
								<li className="left-col collapsing">
									<div className="image-1-1" style={{ width: "80px", backgroundImage: "url(" + item.imageUrl + ")" }}></div>
								</li>
								<li>
									<a onClick={() => this.onEditItem(item)}>{item.name}</a><br/>
									<a href={item.link} target="_blank">Facebook</a>
								</li>
								<li>{item.comment}</li>
								<li>
									{item.dateCreated && (
										<>
											{Format.date(new Date(item.dateCreated))}
											<br />
											<em>{Format.time(new Date(item.dateCreated))}</em>
										</>
									)}
								</li>
								<li className="menu-col">
									<Dropdown placement="bottomRight" trigger={["click"]}
										overlay={
											<Menu>
												<Menu.Item onClick={() => this.onEditItem(item)}>
													<span>
														<i className="icon fas fa-pencil-alt"></i>
														<span>Sửa</span>
													</span>
												</Menu.Item>
											</Menu>
										}>
										<a onClick={(e) => e.preventDefault()}>
											<i className="far fa-ellipsis-v" />
										</a>
									</Dropdown>
								</li>
							</ul>
						))}
					</div>
				</div>
				<Modal width={900} footer={null} destroyOnClose={true} visible={this.state.isItemModel} onCancel={this.onItemCancel}>
					<FeedbackPost
						itemId={this.state.itemId}
						onOk={this.onItemOk}
						onCancel={this.onItemCancel}
						onGetList={this.getList}
					/>
				</Modal>
			</React.Fragment>
		);
	}
}
