import React, { Component } from "react";
import { Row, Col, Input, Button } from "antd";

import { Popup, Msg } from "../../common/component";
import Editor from "../../common/editor";

import { FeedbackPostService } from "./feedbackService";

export default class FeedbackPost extends Component {
	state = {
		isAdd: !this.props.itemId,
		id: this.props.itemId,
		name: null,
		link: null,
		comment: null,
      imageUrl: null,
	};

	componentDidMount() {
		this.init();
	}

	init = () => {
		FeedbackPostService.init({ id: this.state.id }).then((res) => {
			if (!res.success || !res.data) return;
			let newState = {};

			if (this.state.isAdd) {
				newState.name = "";
				newState.link = "";
				newState.comment = "";
				newState.imageUrl = "";
			} else {
            const item = res.data.item;

				newState.name = item.name;
				newState.link = item.link;
				newState.comment = item.comment;
				newState.imageUrl = item.imageUrl;
			}

			this.setState(newState);
		});
	};

	onSubmit = async () => {
		Popup.spin.show();
		const res = await FeedbackPostService.submit({
			id: this.state.id,
			name: this.state.name,
			link: this.state.link,
			comment: this.state.comment,
			imageUrl: this.state.imageUrl,
		});

		Popup.spin.hide();
		if (res.success) {
			Popup.info({ autoClose: true });
			this.props.onOk();
		}

		this.setState({ msgs: res.msgs });
	};

	onSubmitAndContinues = async () => {
		Popup.spin.show();
		const res = await FeedbackPostService.submit({
			name: this.state.name,
			link: this.state.link,
			comment: this.state.comment,
			imageUrl: this.state.imageUrl,
		});

		Popup.spin.hide();
		if (res.success) {
			Popup.info({ autoClose: true });
			this.props.onGetList();
			this.init();
		}

		this.setState({ msgs: res.msgs });
	};

	render() {
		return (
			<div className="form">
				<h1>{this.state.isAdd ? "Thêm" : "Sửa"} danh mục</h1>
				<Row>
					<Col>
						<label>Tên</label>
						<Input value={this.state.name} onChange={({ target: { value } }) => this.setState({ name: value })} />
						<Msg target="name" msgs={this.state.msgs} className="errorMsg" />
					</Col>
				</Row>
				<Row>
					<Col>
						<label>Hình ảnh đại diện</label>
						<Input value={this.state.imageUrl} onChange={({ target: { value } }) => this.setState({ imageUrl: value })} />
						<Msg target="image" msgs={this.state.msgs} className="errorMsg" />
					</Col>
				</Row>
				<Row>
					<Col>
						<label>Link Facebook</label>
						<Input value={this.state.link} onChange={({ target: { value } }) => this.setState({ link: value })} />
						<Msg target="link" msgs={this.state.msgs} className="errorMsg" />
					</Col>
				</Row>
				<Row>
					<Col>
						<label>Tóm tắt</label>
						<Input.TextArea
							autoSize={{ minRows: 2, maxRows: 10 }}
							value={this.state.comment}
							onChange={({ target: { value } }) => this.setState({ comment: value })}
						/>
                  <Msg target="comment" msgs={this.state.msgs} className="errorMsg" />
					</Col>
				</Row>
				<Row>
					<Col>
						<Button type="primary" onClick={this.onSubmit}>
							Đồng ý
						</Button>
						<Button type="default" onClick={this.props.onCancel}>
							Đóng
						</Button>
						{this.state.isAdd ? (
							<Button type="primary" onClick={this.onSubmitAndContinues} style={{ float: "right" }}>
								Đồng ý & Tiếp tục
							</Button>
						) : null}
					</Col>
				</Row>
			</div>
		);
	}
}
