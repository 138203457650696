import React, { Component } from "react";
import { Link } from "react-router-dom";

import * as Const from "../../common/const";

import { MenuService, ContentURL } from "../content/contentService";
import { Format } from "../../common/format";

export default class MenuMain extends Component {
	constructor(props) {
		super(props);

		this.state = {
			categoryList: [],
		};
	}

	componentDidMount() {
		// MenuService.categoryGetList({ parentId: Const.pageCategory, idList: Const.pageMainMenu }).then((res) => {
		// 	if (!res.success) return;

		// 	this.setState({ categoryList: res.itemList ? res.itemList : [] });
		// });
	}

	render() {
		const { categoryList } = this.state;

		return (
			<div className="header-mainbar-menu">
				<Link to="/">
					<span>Trang chủ</span>
				</Link>
				{categoryList.map((item) => (
					<Link key={item.id} to={ContentURL.contentListURL({}, { categoryName: item.name, categoryId: item.id })}>
						<span>{item.name}</span>
					</Link>
				))}
				<Link to="/chinh-sach">
					<span>Chính sách</span>
				</Link>
				<Link to="/lien-he">
					<span>Liên hệ</span>
				</Link>
			</div>
		);
	}
}
