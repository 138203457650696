import React, { Component } from "react";
import { Row, Col, Input, Select, Button, Pagination } from "antd";

import { Format } from "../../common/format";
import { Popup } from "../../common/component";

import { VoucherService } from "./voucherService";

export default class VoucherManage extends Component {
	constructor(props) {
		super(props);

		this.state = {
			itemList: [],
			pageIndex: 1,
			pageSize: 20,
			totalPages: 0,
			totalItems: 0,
			keyword: null,
			orderBy: "latest",
		};
	}

	componentDidMount() {
		this.getList();
	}

	getList = () => {
		Popup.spin.show();
		VoucherService.getList({
			keyword: this.state.keyword,
			orderBy: this.state.orderBy,
			pageIndex: this.state.pageIndex,
		}).then((res) => {
			if (res.success)
				this.setState({
					itemList: res.itemList,
					pageIndex: res.pageIndex,
					totalPages: res.totalPages,
					totalItems: res.totalItems,
				});
			Popup.spin.hide();
		});
	};

	render() {
		return (
			<React.Fragment>
				<h1>Quản lý Voucher</h1>
				<Row align="middle">
					<Col xs={24} sm={12} xl={6}>
						<Input.Search
							placeholder="Từ khóa"
							value={this.state.keyword}
							onChange={({ target: { value } }) => this.setState({ keyword: value })}
							onSearch={() => this.getList()}
						/>
					</Col>
				</Row>
				<Row align="middle" justify="end">
					<Col flex="auto">{this.state.totalItems} Kết quả</Col>
					<Col>
						<Button type="primary" onClick={this.onAddItem}>
							<i className="fa fa-plus" />
							<span>Thêm</span>
						</Button>
					</Col>
					<Col>
						<Select
							placeholder="Sắp xếp"
							style={{ width: "140px" }}
							value={this.state.orderBy}
							onChange={(value) => this.setState({ orderBy: value }, () => this.getList())}
						>
							<Select.Option value="latest">Mới nhất</Select.Option>
							<Select.Option value="oldest">Cũ nhất</Select.Option>
						</Select>
					</Col>
					<Col style={{ marginLeft: "auto" }}>
						<Pagination
							current={this.state.pageIndex}
							total={this.state.totalItems}
							pageSize={this.state.pageSize}
							showSizeChanger={false}
							onChange={(value) => this.setState({ pageIndex: value }, () => this.getList())}
						/>
					</Col>
				</Row>
				<div className="box">
					<div className="table has-left-col has-menu">
						<ol>
							<li>#</li>
							<li>Voucher</li>
							<li>Số Điện thoại</li>
							<li>Ngày tạo</li>
							<li>Ngày thuê</li>
							<li>Ngày trả</li>
							<li></li>
						</ol>
						{this.state.itemList.map((item) => (
							<ul key={item.id}>
								<li className="id-col collapsing">{item.id}</li>
								<li>{item.voucherKey}</li>
                        <li>{item.phoneNumber}</li>
								<li>
									{item.dateCreated && (
										<>
											{Format.date(new Date(item.dateCreated))}
											<br />
											<em>{Format.time(new Date(item.dateCreated))}</em>
										</>
									)}
								</li>
								<li>
									{item.dateRent && (
										<>
											{Format.date(new Date(item.dateRent))}
											<br />
											<em>{Format.time(new Date(item.dateRent))}</em>
										</>
									)}
								</li>
								<li>
									{item.dateReturn && (
										<>
											{Format.date(new Date(item.dateReturn))}
											<br />
											<em>{Format.time(new Date(item.dateReturn))}</em>
										</>
									)}
								</li>
							</ul>
						))}
					</div>
				</div>
				<Row gutter={[0, 10]}>
					<Col style={{ marginLeft: "auto" }}>
						<Pagination
							current={this.state.pageIndex}
							total={this.state.totalItems}
							pageSize={this.state.pageSize}
							showSizeChanger={false}
							onChange={(value) => this.setState({ pageIndex: value }, () => this.getList())}
						/>
					</Col>
				</Row>
			</React.Fragment>
		);
	}
}
