import React, { Component } from "react";
import { Link } from 'react-router-dom';

export default class MenuTop extends Component {
	render() {
		return (
			<div className="header-topbar-menu">
				<Link to="/"><i className="fal fa-comment-dots"></i><span>Hỏi đáp</span></Link>
				<Link to="/" className="career"><i className="fal fa-clipboard-check"></i><span>Tuyển dụng</span></Link>
			</div>
		);
	}
}