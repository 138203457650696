import React, { Component } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { Row, Col, Input, Button, DatePicker, Select  } from "antd";
import { PhoneTwoTone } from '@ant-design/icons';
import OwlCarousel from "react-owl-carousel2";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Locale } from "../../common/locale";

import { Popup, Msg } from "../../common/component";
import { FeedbackManageService } from "../feedback/feedbackService";
import { LogActionService } from "../logAction/logActionService";
import { VoucherService } from "../voucher/voucherService";
import { HomeContentService } from "../content/contentService";
import { FileService } from "../file/fileService";

const MINUTE_MS = 60000;
export default class Home extends Component {
	state = {
		isPopup: false,
		feedbackList: [],
		pageIndex: 1,
		orderBy: 'latest',
		remainNumber: 0,
		phoneNumber: null,
		isVoucherKeyCopy: false,
		partnerList: [],
		voucherList: [],
		introduceList: [],
	};

	componentDidMount() {
		this.init();
	}
	componentWillUnmount(){
		clearInterval(this.interval);
	}

	init = () => {
		FeedbackManageService.getList({
			orderBy: this.state.orderBy,
			pageIndex: this.state.pageIndex,
		}).then((res) => {
			if (!res.success) return;

			this.setState({
				feedbackList: res.itemList,
				remainNumber: 237 - res.totalItems,
			});
		});

		HomeContentService.getList({ categoryId: 3 }).then((res) => {
			if (!res.success) return;

			this.setState({ partnerList: res.itemList });
		});

		HomeContentService.getList({ categoryId: 4 }).then((res) => {
			if (!res.success) return;

			this.setState({ voucherList: res.itemList });
		});

		HomeContentService.getList({ categoryId: 10003, numberItem: 1, hasDescription: true }).then((res) => {
			if (!res.success) return;

			this.setState({
				introduceList: res.itemList ? res.itemList : [],
			});
		});
	};

	onClosePopup = () => {
		this.setState({ isPopup: false });
	};

	onLogAction = async (action) => {
		const res = await LogActionService.submit({
			action: action,
		})
	}
	onVoucherKeyCopy = () => {
		this.setState({ isVoucherKeyCopy: true });
	};

	onSubmitVoucher = async (phone) => {
		Popup.spin.show();
		const res = await VoucherService.submit({
			phoneNumber: this.state.phoneNumber,
			dateRent: this.state.dateRent,
			dateReturn: this.state.dateReturn
		});
		Popup.spin.hide();

		if (res.success) {
			Popup.info({
				title: "Mã đặt xe của bạn",
				content: (<>
						<CopyToClipboard text={res.data} onCopy={this.onVoucherKeyCopy}>
							<div className="voucher-key">
								<span>{res.data}</span>
								<i className={"fal fa-" + (this.state.isVoucherKeyCopy ? "check" : "copy")} />
							</div>
						</CopyToClipboard>
					<div>- Mã đặt xe tại Thuê Xe Rất Xịn</div>
					<div>- Cám ơn quý khách đã quan tâm và sử dụng dịch vụ tại Thuê Xe Rất Xịn - Đà Lạt</div>
				</>),
			});
		}
		
		this.setState({ msgs: res.msgs });
	}

	disabledSubmissionDate = (current) => {
    if (!current) {
        return false;
    }
    return moment().add(-1, "days") >= current
}

	render() {
		const { partnerList, voucherList, introduceList } = this.state;
		
		const bannerOptions = {
			items: 1,
			rewind: true,
			autoplay: true,
			smartSpeed: 800,
			autoplayHoverPause: true,
		};

		const partnerOptions = {
			margin: 20,
			autoWidth: true,
			dots: false,
			nav: false,
			navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],
			rewind: true,
			smartSpeed: 500,
		};

		const voucherOptions = {
			margin: 20,
			autoWidth: true,
			dots: false,
			nav: true,
			navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],
			rewind: true,
			autoplay: true,
			smartSpeed: 500,
		};

		return (
			<React.Fragment>
				<section className="home-banner-main">
					<div className="home-container">
						<div className="home-banner-wrap">
								<div className="banner">
									<OwlCarousel options={bannerOptions}>
										<div className="item" style={{ backgroundImage: "url(/image/banner/7.jpg)" }}></div>
										<div className="item" style={{ backgroundImage: "url(/image/banner/4.jpg)" }}></div>
										<div className="item" style={{ backgroundImage: "url(/image/banner/5.jpg)" }}></div>
										<div className="item" style={{ backgroundImage: "url(/image/banner/1.jpg)" }}></div>
										<div className="item" style={{ backgroundImage: "url(/image/banner/8.jpg)" }}></div>
										<div className="item" style={{ backgroundImage: "url(/image/banner/9.jpg)" }}></div>
									</OwlCarousel>
							</div>
						</div>
					</div>
				</section>
				<section className="voucher">
					<div className="container">
						<div className="title-home">Thông tin đặt xe</div>
						<div className="form-saleoff">
							<Row justify="center">
								<Col xs={24} sm={24} md={10} lg={12}>
									<Input size="large" placeholder="SĐT đăng ký thuê xe" value={this.state.phoneNumber}
													onChange={({ target: { value } }) => this.setState({ phoneNumber: value })} prefix={<PhoneTwoTone className="transform-180" />} />
									<Msg target="phone" msgs={this.state.msgs} className="errorMsg" />
								</Col>
								<Col xs={12} sm={6} md={5} lg={3}>
			            <DatePicker
										inputReadOnly
										value={this.state.dateRent ? moment(this.state.dateRent): ''}
			              placeholder="Ngày nhận xe" 
			              format={Locale.getFormat().dateFormat}
			              //style={{ width: "150px" }}
			              onChange={(value) => this.setState({ dateRent: value ? value.format() : null })}
										disabledDate={this.disabledSubmissionDate}
			            />
			            <Msg target="daterent" msgs={this.state.msgs} className="errorMsg" />
          			</Col>
								<Col xs={12} sm={6} md={5} lg={3}>
			            <DatePicker
										inputReadOnly
										value={this.state.dateReturn ? moment(this.state.dateReturn): ''}
			              placeholder="Ngày trả xe" 
			              format={Locale.getFormat().dateFormat}
			              //style={{ width: "150px" }}
			              onChange={(value) => this.setState({ dateReturn: value ? value.format() : null })}
										disabledDate={this.disabledSubmissionDate}
			            />
			            <Msg target="datereturn" msgs={this.state.msgs} className="errorMsg" />
          			</Col>
								<Col xs={24} sm={8} md={4} lg={4}>
									<Button type="primary" size='large' onClick={this.onSubmitVoucher} style={{width:'100%'}}>Đặt xe</Button>
								</Col>
							</Row>
						</div>
					</div>
				</section>
				<section className="price-rent">
					<div className="container">
						<div className="title-logo">
							<img src="/image/renroll.png" alt="Rất Xịn"/>
						</div>
						<div className="content">
							<div className="title-home">Cho thuê xe rất xịn</div>
							<div className="description-home">
								{introduceList.map((item) => (
									<div key={item.id} dangerouslySetInnerHTML={{ __html: item.description }}></div>
									))}
							</div>
						</div>
						<Row>
							<Col xs={0} md={6}>
								<Link to="/" className="item">
									<span className="image" style={{ backgroundImage: "url(/image/rent/renroll-moto.jpg)" }} />
								</Link>
							</Col>
							<Col xs={0} md={6}>
								<Link to="/" className="item">
									<span className="image" style={{ backgroundImage: "url(/image/rent/1.png)" }} />
								</Link>
							</Col>
							<Col xs={0} md={6}>
								<Link to="/" className="item">
									<span className="image" style={{ backgroundImage: "url(/image/rent/2.png)" }} />
								</Link>
							</Col>
							<Col xs={0} md={6}>
								<Link to="/" className="item">
									<span className="image" style={{ backgroundImage: "url(/image/rent/3.png)" }} />
								</Link>
							</Col>
						</Row>
					</div>
				</section>
				
				<section className="info-rent">
						<div className="contact">
							<Row justify="center" align="center">
								<Col xs={24} md={11}>
									<div className="info-contact">
										<div className="contact-us">Bấm ở đây để gọi ngay cho Rất Xịn</div>
										<div className="phone-number">
											<div className="title-home">
												<i className="far fa-hand-point-right"></i>
													<a href="tel:0899184307" onClick={() => this.onLogAction("call middle")}>0899.184.307</a>
												<i className="far fa-hand-point-left"></i>
											</div>
										</div>
										
									</div>
								</Col>
								<Col xs={0} md={2}>
									<div className="or">HOẶC</div>
								</Col>
								<Col xs={24} md={11}>
									<div className="info-contact-fb">
										<div className="contact-us">Liên hệ chúng tôi qua Facebook</div>
										<a href="https://www.facebook.com/ChothuexeRATXIN" target="_blank">
											<i className="fab fa-facebook-f"> </i>
											<span className="contact-number"> THUÊ XE RẤT XỊN</span>
										</a>
									</div>
								</Col>
							</Row>
						</div>
				</section>
				{voucherList.length > 0 &&
					<section className="scroll-voucher">
					 <div className="title">Ưu đãi mới nhất</div>
					 <div className="container">
							<OwlCarousel options={voucherOptions}>
								{voucherList.map((item) => (
									<div key={item.id} className="item">
										<a href={item.link} target="_blank" rel="noopener noreferrer">
											<img src={FileService.imageURL(item.imageId, 512)} alt={item.name} />
										</a>
									</div>
								))}
							</OwlCarousel>
					 </div>
					</section>}

				<section className="feedback"> 
					<div className="container"> 
						<div className="title-logo"> 
							<img src="/image/renroll.png" alt="Rất Xịn"/> 
						</div> 
						<div className="title-home">Khách hàng nói về chúng tôi</div>
							<div className="fb-comment-box"> 
								<div className="_56q9"> 
									<div className="_2pi8"> 
										<div className="_491z clearfix"> 
											<div className="_ohe lfloat"> <span> <span className="_50f7">237 Comments</span> </span> </div> 
											<div className="_ohf rfloat"> 
													<span className="_pup">Sort by </span> 
													<div alignh="right" className="_3-8_ _6a _6b" valign="middle"> 
														<a className="_55pi _2agf _4o_4 _4jy0 _4jy3 _517h _51sy _42ft" href="/"> 
															<span className="_55pe">Oldest</span> 
															<span alt="" className="_3-99 _4o_3"> <i alt="" className="img sp_6wAmEqWDE--_3x sx_4111e7"></i> </span> 
														</a> 
													</div> 
											</div> 
										</div> 
										<div className="_4uyl _1zz7 _2392 clearfix" direction="left"> 
											<div className="_ohe lfloat"> 
												<div className="img _8o _8s UFIImageBlockImage"></div> 
											</div> 
											<div className=""> 
												<div className="UFIImageBlockContent _42ef"> 
												<div className="UFIInputContainer"> 
														<div className="_1cb _5yk1" role="presentation"> 
															<div className="_5yk2"> 
																<div className="_5rp7"> 
																	<div className="_1p1t"> 
																		<div className="_1p1v" id="placeholder-36h3o">Add a comment...</div> 
																	</div> 
																	<div className="_5rpb"> 
																		<div aria-autocomplete="list" aria-controls="js_0" aria-describedby="placeholder-36h3o" aria-expanded="false" aria-label="Add a comment" className="notranslate _5rpu" role="combobox" > 
																		<div data-contents="true"> 
																			<div className="" data-block="true" data-editor="36h3o" data-offset-key="d2pj8-0-0"> 
																				<div data-offset-key="d2pj8-0-0" className="_1mf _1mj"> 
																					<span data-offset-key="d2pj8-0-0"> <br data-text="true"/> </span> 
																				</div> 
																			</div> 
																		</div> 
																	</div> 
																</div> 
															</div> 
														</div> 
													</div> 
													<div className="UFICommentAttachmentButtons clearfix"></div> 		
												</div> 
											</div> 
										</div> 
									</div> 
									<div className="_4k-6" > 
										<div id="item-comment">
										{this.state.feedbackList.map((item) => (
											<div key={item.id} className="_3-8y _5nz1 clearfix" direction="left"> 
												<div className="_ohe lfloat"> 
													<a href={item.link} target="_blank" className="img _8o _8s UFIImageBlockImage"> 
														<img className="_1ci img" src={item.imageUrl} alt=""/> 
													</a> 
												</div> 
												<div className="UFIImageBlockContent _42ef comment-fb clearfix" direction="right"> 
													<span> <a target="_blank" className="UFICommentActorName" dir="ltr" href={item.link}>{item.name}</a> </span> 
													<div className="_3-8m"> <div className="_30o4"> <span> <span className="_5mdd"> <span>{item.comment}</span> </span> </span></div> 
														<div className="_2vq9 fsm fwn fcg"> 
															<a href="#">Like</a> 
															<span aria-hidden="true"> · </span> 
															<a href="#">Reply</a> 
															<span aria-hidden="true"> · </span> 
															<span> 
																<a className="uiLinkSubtle" href="https://www.facebook.com/pg/ChothuexeRATXIN?fb_comment_id=3394970587182751_3397808720232271" target="_blank"> 
																	<abbr aria-label="1 days ago" minimize="true" className="UFISutroCommentTimestamp livetimestamp">{item.dayAgo}</abbr> 
																</a> 
															</span> 
														</div>
													</div> 
												</div> 
											</div> 
										))}											
										</div> 
									<div className="_5o4h"> 
										<button className="_1gl3 _4jy0 _4jy3 _517h _51sy _42ft" value="10" href="/"> 
											Load <span id="number-load-more">{this.state.remainNumber}</span> more comments 
										</button> 
									</div> 
								</div> 
								<div className="_5lm5 _2pi3 _3-8y"> 
									<div direction="left" className="clearfix"> 
										<div className="_ohe lfloat"> <i className="img _8o _8r img sp_6wAmEqWDE--_1_5x sx_f276f1" alt=""></i> </div> 
											<div className="_42ef _8u"> 
												<a target="_blank" href="https://developers.facebook.com/products/social-plugins/comments/?utm_campaign=social_plugins&amp;utm_medium=offsite_pages&amp;utm_source=comments_plugin"> Facebook Comments Plugin</a> 
											</div>
									</div> 
								</div> 
							</div> 
						</div> 
					</div> 
					</div> 
				</section>
				<section className="fanpage">
					<div className="fb-page" data-href="https://www.facebook.com/ChothuexeRATXIN" data-tabs="timeline" data-width="350" data-height="150" data-small-header="true" data-adapt-container-width="false" data-hide-cover="false" data-show-facepile="true"><blockquote cite="https://www.facebook.com/ChothuexeRATXIN" className="fb-xfbml-parse-ignore"><a href="https://www.facebook.com/ChothuexeRATXIN">Cho thuê xe Rất Xịn</a></blockquote></div>
				</section>
				<section className="contact">
					<div className="container">
						<div className="title-logo">
							<img src="/image/renroll.png" alt="Rất Xịn"/>
					</div>
               <div className="title-home">Địa chỉ liên hệ</div>
						<div className="description-home">
							<div>80 Lý Tự Trọng, P.2, Tp. Đà Lạt.</div>
							<div>05 Nguyễn Thái Học, P.1, Tp. Đà Lạt.</div>
							<div>69c Nguyễn Văn Trỗi, P.2, Tp. Đà Lạt.</div>
							<div>42 Nguyễn Trãi, P.9, Tp. Đà Lạt.</div>
						</div>
						<div className="map">
						<iframe title="map" src="https://www.google.com/maps/d/u/0/embed?mid=1Zxn0LeVD58-1fU5vqFaJc0m0yyJOTRDb" width="100%" height="480"></iframe>
						</div>
					</div>
				</section>
				<section className="partner">
					<div className="title">Đối tác của chúng tôi</div>
					<div className="container">
						{partnerList.length > 0 &&
							<OwlCarousel options={partnerOptions}>
								{partnerList.map((item) => (
									<div key={item.id} className="item">
										<a href={item.link} target="_blank" rel="noopener noreferrer">
											<img src={FileService.imageURL(item.imageId, 384)} alt={item.name} />
										</a>
									</div>
								))}
							</OwlCarousel>
						}
					</div>
				</section>
				<section className="call-me">
					<a href="tel:0899184307" onClick={() => this.onLogAction("call bottom")}><i className="fas fa-phone-alt"></i> Gọi</a>
				</section>
			</React.Fragment>
		);
	}
}
